import VueRouter from 'vue-router';

import {
  isAdmin,
  isFriend,
  isUser,
  isAuthenticated,
  isActivationUsed,
  isLinkValid,
  checkActivity,
  isAdult,
  checkDOB,
  isMaintenance,
} from './routes-guard';

const router = new VueRouter({
  mode   : 'history',
  routes : [
    {
      path      : '/404',
      component : () => import('./components/NotFound.vue'),
    },
    {
      path      : '/expired-link',
      component : () => import('./components/Error.vue'),
    },
    {
      path     : '*',
      redirect : '/404',
    },
    {
      path      : '/unauthorized',
      component : () => import('./components/Unauthorized.vue'),
    },
    {
      path      : '/maintenance',
      name      : 'maintenance',
      component : () => import('./pages/MaintenancePage/index'),
    },
    {
      path : '/invest',
    },
    {
      path        : '/',
      component   : () => import('./pages/MasterPage'),
      beforeEnter : isAuthenticated,
      props       : true,
      children    : [ {
        path      : '/',
        component : () => import('./pages/HomePage/index'),
      }, {
        path      : 'parents',
        component : () => import('./pages/ParentsPage/index'),
      }, {
        path      : 'schools',
        component : () => import('./pages/SchoolsPage/index'),
      }, {
        path        : 'login',
        component   : () => import('./pages/LoginPage/index'),
        beforeEnter : isMaintenance,
      }, {
        path        : 'sign-up',
        component   : () => import('./pages/SignupPage/index'),
        beforeEnter : checkDOB,
      }, {
        path        : 'free-sign-up',
        component   : () => import('./pages/SignupPage/index'),
        beforeEnter : checkDOB,
      }, {
        path        : 'forgot-password',
        component   : () => import('./pages/ForgotPasswordPage/index'),
        beforeEnter : isMaintenance,
      }, {
        path        : 'reset-password/:userId/:email/:token',
        component   : () => import('./pages/ResetPasswordPage/index'),
        beforeEnter : isMaintenance,
      } ],
    },
    {
      path        : '/our-mission',
      component   : () => import('./components/OurMission.vue'),
      beforeEnter : isAuthenticated,
    },
    {
      path      : '/pricing/:step?',
      name      : 'pricing',
      component : () => import('./components/Pricing.vue'),
    },
    {
      path      : '/registration-invite/:inviter/:email/:userType?/:shortToken?',
      component : () =>
        import('./components/registration/RegistrationByInvite'),
      beforeEnter : isLinkValid,
    },
    {
      path      : '/activate-account/:userId/:email',
      component : () => import('./pages/AccountActivationPage/index'),
    },
    {
      path      : '/auth/twitter/callback',
      component : () => import('./components/authentication/TwitterCallback'),
    },
    {
      path      : '/fReq/:chatId/:userType',
      component : () => import('./components/FriendRequest'),
    },
    {
      path        : '/',
      component   : () => import('./pages/UserMasterPage'),
      beforeEnter : isUser,
      props       : true,
      children    : [ {
        path        : 'dashboard',
        component   : () => import('./pages/DashboardPage/index'),
        beforeEnter : checkActivity,
      }, {
        path      : 'music',
        component : () => import('./pages/MusicPage/index'),
        },{
          path: '/music/:type/:id/:slug',
          beforeEnter: isUser,
          component: () => import('./pages/MusicSelectedPage/index'),
          name: 'MusicSelectedPage',
        },
        {
          path: '/music/:type/:id/:slug',
          beforeEnter: isUser,
          component: () => import('./pages/MusicSelectedPage/index'),
          name: 'MusicSelectedPage',
        }, 
        {
        path      : 'profile',
        component : () => import('./pages/ProfilePage/index'),
      }, {
        path      : 'profile/:contentType',
        component : () => import('./pages/ProfilePage/index'),
      }, {
        path      : 'media/:id/:wistiaId',
        component : () => import('./pages/MediaPage/index'),
      }, {
        path        : 'manage-profiles',
        component   : () => import('./pages/ManageProfilesPage/index'),
        beforeEnter : isAdult,
        meta        : { requiresPinVer : true },
      }, {
        path      : 'interact',
        component : () => import('./pages/InteractivePage/index'),
      }, {
        path      : 'categories',
        component : () => import('./pages/CategoriesPage/index'),
      }, {
        path      : 'category/:id/:slug',
        component : () => import('./pages/CategoryPage/index'),
      }, {
        path      : 'favorites',
        component : () => import('./pages/FavoritesPage/index'),
      },
      
      // {
      //   path      : 'plans',
      //   component : () => import('./pages/SubscriptionPage/Plans'),
      // }, {
      //   path        : 'subscription',
      //   component   : () => import('./pages/SubscriptionPage/index'),
      //   beforeEnter : isAdult,
      //   meta        : { requiresPinVer : true },
      // }, {
      //   path      : 'subscription-process/:action?/:sessionId?',
      //   component : () => import('./pages/SubscriptionPage/Process'),
      // }, {
      //   path        : 'payment-method',
      //   component   : () => import('./pages/PaymentMethodPage/index'),
      //   beforeEnter : isAdult,
      //   meta        : { requiresPinVer : true },
      // }, {
      //   path        : 'payment-details',
      //   component   : () => import('./pages/PaymentDetailsPage/index'),
      //   beforeEnter : isAdult,
      //   meta        : { requiresPinVer : true },
      // },
      ],
    },
    {
      path        : '/browse',
      redirect    : '/404',
      component   : () => import('./components/user/Browse'),
      beforeEnter : isUser,
      props       : true,
      children    : [
        {
          path      : 'main',
          name      : 'main',
          component : () => import('./components/user/Main'),
          meta      : {
            menuId : 'main',
          },
          beforeEnter : checkActivity,
        },
        {
          path      : 'main/:action?/:sessionId?',
          name      : 'main-create-subscription',
          component : () => import('./components/user/Main'),
          meta      : {
            menuId : 'main',
          },
          beforeEnter : checkActivity,
        },
        {
          path      : 'categories',
          name      : 'categories',
          component : () => import('./components/user/Categories'),
          meta      : {
            menuId : 'categories',
          },
        },
        {
          path      : 'my-videos',
          name      : 'my-videos',
          component : () => import('./components/user/MyVideos'),
          meta      : {
            menuId : 'my-videos',
          },
        },
        {
          path      : 'music',
          name      : 'music',
          component : () => import('./components/user/category/MusicCategories'),
          meta      : {
            menuId : 'music',
          },
          beforeEnter : isActivationUsed,
        },
        {
          path      : 'music/album/:albumId',
          name      : 'music-album',
          component : () => import('./components/user/MusicAlbum'),
          meta      : {
            menuId : 'music',
          },
        },
        {
          path      : 'school',
          name      : 'school',
          component : () => import('./components/user/School'),
          meta      : {
            menuId : 'school',
          },
          beforeEnter : isActivationUsed,
        },
        {
          path      : 'search',
          name      : 'search',
          component : () => import('./components/user/Search'),
          meta      : {
            menuId : 'main',
          },
        },
        {
          path      : 'classroom/:classroomId',
          name      : 'classroom',
          component : () => import('./components/user/Classroom'),
          meta      : {
            menuId : 'school',
          },
          beforeEnter : isActivationUsed,
        },
        {
          path      : 'moderator',
          name      : 'moderator',
          component : () => import('./components/user/Moderator'),
          meta      : {
            menuId : 'school',
          },
          beforeEnter : isActivationUsed,
        },
        {
          path      : 'influencer',
          name      : 'influencer',
          component : () => import('./components/user/Influencer'),
          meta      : {
            menuId : 'school',
          },
          beforeEnter : isActivationUsed,
        }, {
          path      : 'influencer/:influencerId',
          name      : 'influencer-profile',
          component : () => import('./components/user/InfluencerProfile'),
          meta      : {
            menuId : 'school',
          },
          beforeEnter : isActivationUsed,
        },
        {
          path      : 'broadcast/:roomName',
          name      : 'broadcast',
          component : () => import('./components/user/Broadcast'),
          meta      : {
            menuId : 'school',
          },
          beforeEnter : isActivationUsed,
        },
        {
          path      : 'live-streaming',
          name      : 'live-streaming',
          component : () => import('./components/user/LiveStreaming'),
          meta      : {
            menuId : 'live-streaming',
          },
        },
        {
          path      : 'leaderboard',
          name      : 'leaderboard',
          component : () => import('./components/user/Leaderboard'),
          meta      : {
            menuId : 'main',
          },
        },
        {
          path        : 'profile/:userId',
          name        : 'public-profile',
          component   : () => import('./components/user/PublicProfile'),
          beforeEnter : isFriend,
        },
        {
          path      : 'profile/:userId/:contentType',
          name      : 'public-profile-content',
          component : () => import('./components/user/PublicProfile'),
        },
      ],
    },
    {
      path        : '/media-list/:id/:title',
      component   : () => import('./components/user/MediaList'),
      beforeEnter : isUser,
      meta        : {
        menuId : 'categories',
      },
    },
    {
      path      : '/notification-all',
      name      : 'notification-all',
      component : () =>
        import('./components/notification/NotificationListAll'),
      beforeEnter : isUser,
    },
    {
      path        : '/classroom/:classroomId/qr-code',
      name        : 'qr-code',
      component   : () => import('./components/user/classroom/QRCode'),
      beforeEnter : isUser,
      meta        : {
        menuId : 'school',
      },
    },

    // {
    //   path        : '/subscription-old',
    //   name        : 'subscription',
    //   component   : () => import('./components/user/Subscription'),
    //   beforeEnter : isUser,
    // },
    // {
    //   path        : '/subscription-old/:action?/:sessionId?',
    //   name        : 'subscription-process',
    //   component   : () => import('./components/user/Subscription'),
    //   beforeEnter : isUser,
    // },
    // {
    //   path        : '/plans-old',
    //   name        : 'plans',
    //   component   : () => import('./components/user/subscription/Plans'),
    //   beforeEnter : isUser,
    // },
    // {
    //   path        : '/payment-methods',
    //   name        : 'payment-methods',
    //   component   : () => import('./components/user/subscription/PaymentMethods'),
    //   beforeEnter : isUser,
    // },
    // {
    //   path        : '/payment-details-old',
    //   name        : 'payment-details-old',
    //   component   : () => import('./components/user/subscription/PaymentDetails'),
    //   beforeEnter : isUser,
    // },
    {
      path      : '/download',
      name      : 'download',
      component : () => import('./components/Download'),
    },
    {
      path      : '/download-mobile',
      name      : 'download-mobile',
      component : () => import('./components/DownloadMobile'),
    },

    // {
    //   path      : '/payment/:subscriptionId/:status?/:sessionId?',
    //   name      : 'payment',
    //   component : () => import('./components/Payment'),
    // },
    {
      path      : '/manual/privacy-policy',
      name      : 'terms',
      component : () => import('./components/manual/PrivacyPolicy'),
    },
    {
      path      : '/manual/terms-of-use',
      name      : 'privacy',
      component : () => import('./components/manual/TermsOfUse'),
    },
    {
      path      : '/manual/community-standards',
      name      : 'community-standards',
      component : () => import('./components/manual/CommunityStandards'),
    },
    {
      path      : '/admin/login',
      component : () => import('./components/admin/login/index'),
    },
    {
      path        : '/admin',
      name        : 'admin',
      component   : () => import('./components/admin/Main2'),
      beforeEnter : isAdmin,
      props       : true,
      children    : [
        {
          path      : 'dashboard',
          component : () => import('./components/admin/Dashboard'),
        },
        {
          path      : 'featured',
          component : () => import('./components/admin/featured/Featured'),
        },
        {
          path      : 'categories',
          component : () => import('./components/admin/category/Categories'),
        },
        {
          path      : 'categories/interact',
          component : () =>
            import('./components/admin/category/interact/index'),
        },
        {
          path      : 'users',
          component : () => import('./components/admin/userlist/UserList'),
        },
        {
          path      : 'influencers',
          component : () =>
            import('./components/admin/influencers/Influencers'),
        },
        {
          path      : 'enterprise',
          component : () =>
            import('./components/admin/enterprise/EnterpriseList'),
        },
        {
          path      : 'music-albums',
          component : () => import('./components/admin/mobile_music/AlbumList'),
        },
        {
          path      : 'music-albums/create',
          component : () =>
            import('./components/admin/mobile_music/CreateAlbum'),
        },
        {
          path      : 'challenges-albums',
          component : () =>
            import('./components/admin/challenges_music/AlbumList'),
        },
        {
          path      : 'challenges-albums/create',
          component : () =>
            import('./components/admin/challenges_music/CreateAlbum'),
        },
        {
          path      : 'videos',
          component : () => import('./components/admin/media/VideoList'),
        },
        {
          path      : 'videos/upload',
          component : () => import('./components/admin/media/MediaUpload'),
        },
        
        {
          path      : 'announcements',
          component : () =>
            import('./components/admin/announcement/AnnouncementList'),
        },
        {
          path      : 'live-broadcast',
          component : () =>
            import('./components/admin/broadcast/LiveBroadcast'),
        },
        {
          path      : 'mobile-filter-assets',
          component : () =>
            import('./components/admin/mobile_filter/FilterList'),
        },
        {
          path      : 'moderate-content/:uccId?',
          component : () =>
            import('./components/admin/moderate_content/ModerateContent'),
        },
        {
          path      : 'playlist/:page',
          name      : 'playlist',
          component : () => import('./components/admin/playlist/index'),
        },
        {
          path      : 'playlist/view/:playlistId',
          name      : 'playlist-view',
          component : () => import('./components/admin/playlist/PlaylistView'),
        },
        {
          path      : 'h3-characters',
          component : () => import('./components/admin/h3_characters/index'),
        },
        {
          path      : 'web-settings',
          component : () => import('./components/admin/web_settings/index'),
        },
      ],
    },
    {
      path      : '/logout',
      name      : 'logout',
      component : () => import('./components/common/Logout'),
    },
  ],
});

export default router;