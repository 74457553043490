/* eslint-disable camelcase */
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import es6Promise from 'es6-promise';

es6Promise.polyfill();
import VueRouter from 'vue-router';
import Axios from './axios-auth';
import VueHead from 'vue-head';
import App from './App';
import router from './routes';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VS2 from 'vue-script2';
import GAuth from 'vue-google-oauth2';
import VueEvents from 'vue-events';
import VModal from 'vue-js-modal';
import ToggleButton from 'vue-js-toggle-button';
import Vuelidate from 'vuelidate';
import Notifications from 'vue-notification';
import store from './store';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VuePlyr from 'vue-plyr';
import Lightbox from 'vue-my-photos';
import VueIsInView from 'vue-is-in-view';
import PubNubVue from 'pubnub-vue';
import Croppa from 'vue-croppa';
import VueTour from 'vue-tour';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import vSelect from 'vue-select';
import Aplayer from 'vue-aplayer';
import vueEventCalendar from 'vue-event-calendar';
import VueFacebookPixel from 'vue-analytics-facebook-pixel';
import VueSidebarMenu from 'vue-sidebar-menu';
import VueGtag from "vue-gtag";
import VueGtm from '@gtm-support/vue2-gtm';
import VueGoogleCharts from 'vue-google-charts'

import 'vue-plyr/dist/vue-plyr.css';
import 'animate.css/animate.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-select/dist/vue-select.css';
import 'vue-event-calendar/dist/style.css';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

Vue.config.productionTip = false;

/**
 * Mount with Global
 */
Vue.use(VueRouter);
Vue.use(VueHead);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VS2);
Vue.use(vueEventCalendar, {locale : 'en', color : '#068bcd' });
Vue.use(VueFacebookPixel, { })
Vue.use(VueGoogleCharts);

// TODO: Get google id from env variable.
Vue.use(GAuth, {
  clientId : process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope    : 'profile email https://www.googleapis.com/auth/plus.login',
});
Vue.use(VuePlyr);
Vue.use(VueEvents); // TODO: for vuetable.. change to vuex.
Vue.use(VModal, {
  dialog                : true,
  dynamic               : true,
  injectModalsContainer : true,
});
Vue.use(ToggleButton);
Vue.use(Vuelidate);
Vue.use(Notifications);
Vue.use(VueMoment, {
  moment,
});

Vue.component('lightbox', Lightbox);
Vue.use(Lightbox);
Vue.use(VueIsInView);

Vue.use(PubNubVue, {
  subscribeKey : process.env.VUE_APP_PUBNUB_SUBSCRIBE_KEY,
  publishKey   : process.env.VUE_APP_PUBNUB_PUBLISH_KEY,
});
Vue.use(Croppa);
Vue.use(VueTour);
Vue.use(vueAwesomeCountdown, 'vac');
Vue.use(VueSidebarMenu);
Vue.component('pagination', require('laravel-vue-pagination'));

Vue.component('v-select', vSelect);
Vue.component('aplayer', Aplayer);

Vue.prototype.$http = Axios;
Vue.analytics.fbq.init(process.env.VUE_APP_FACEBOOK_PIXEL_CODE, {});
Vue.use(VueGtag, {
  config : { id : process.env.VUE_APP_GOOGLE_MEASUREMENT_ID },
  pageTrackerTemplate(to) {
    return {
      page_title    : document.title,
      page_path     : to.path,
      page_location : window.location.href,
      environment   : process.env.VUE_APP_ENVIRONMENT,
    }
  },
  includes : [{ id : process.env.VUE_APP_GOOGLE_MEASUREMENT_ID_2 }],
}, router)

Vue.use( VueGtm, { id        : process.env.VUE_APP_GOOGLE_MEASUREMENT_ID_2,
  vueRouter : router } )

//needs to run before vuex is created for router guard
store.dispatch('auth/autoLogin');

new Vue({
  el     : '#app',
  store,
  router,
  render : h => h(App),
});